import React, { useEffect } from "react"

export default function USRedirect() {
  useEffect(() => {
    if (typeof window != "undefined") {
      window.location.href = "/"
    }
  }, [])
  return <></>
}
